import React from 'react'

import Page from '~/templates/Page'

const CommingSoon = () => (
  <Page>
    <div style={{ textAlign: 'center' }}>
      <h1>Coming Soon</h1>
    </div>
  </Page>
)

export default CommingSoon
